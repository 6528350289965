<template>
  <div id="dailyReportsContainer">
    <vs-breadcrumb class="mb-5">
      <li>
        <router-link to="/">Domov</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">Intermodalni prevozi</li>
    </vs-breadcrumb>

    <vs-button radius size="large" color="success" icon-pack="feather" icon="icon-plus-circle" id="addFabBtn"
               title="Dodaj intermodalni prevoz" @click.stop="addIntermodalReservation()"></vs-button>


    <vs-row>
      <vs-col vs-type="flex" vs-justify="start" vs-align="start" vs-w="6">
        <vs-button color="primary" size="small" class="mt-1 mr-2" icon="keyboard_arrow_left"
                   @click="() => {selectedDate = moment(selectedDate).subtract(1, 'M').toDate(); onDateChange()}"></vs-button>
        <datepicker :minimumView="'month'" :maximumView="'year'" :language="slSI" :format="customDateFormatter"
                    :monday-first="true"
                    @input="onDateChange" v-model="selectedDate"></datepicker>
        <vs-button color="primary" size="small " class="mt-1 ml-2" icon="keyboard_arrow_right"
                   @click="() => {selectedDate = moment(selectedDate).add(1, 'M').toDate(); onDateChange()}"></vs-button>
      </vs-col>
    </vs-row>

    <div v-if="!reservationsData" class="mt-2">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="start" vs-align="start" vs-w="6">
          <em class="mt-5">Ni vnosov za prikaz</em>
        </vs-col>
      </vs-row>
    </div>

    <div v-if="reservationsData" class="mt-2">

      <div class="vs-component vs-con-table vs-table-primary">
        <header class="header-table vs-table--header">
          <header class="vs-navbar nabarx vs-navbar-null vs-navbar-color-transparent" style="background: transparent;">
            <div class="vs-navbar--header">
              <button class="vs-navbar--btn-responsive"><span class="btn-responsive-line line--1"></span><span
                class="btn-responsive-line line--2"></span><span class="btn-responsive-line line--3"></span></button>
              <div><h3 class="vs-navbar--title py-4">
                Intermodalni prevozi
              </h3></div>
            </div>
            <div class="vs-con-items"></div>
          </header>
        </header>


        <div class="con-tablex vs-con-table vs-table--content">
          <div class="vs-con-tbody vs-table--tbody mb-10">
            <table class="vs-table vs-table--tbody-table dailyReportsTable">
              <thead class="vs-table--thead">
              <!--              <tr>-->
              <!--                <th colspan="9">-->
              <!--                  <div class="vs-table-text">-->
              <!--                    <h4><b>{{ moment(dateItemIndex, 'DD-MM-YYYY').format('dddd').toUpperCase() }}</b><br/>-->
              <!--                      <small>{{ moment(dateItemIndex, 'DD-MM-YYYY').format('DD. MM. YYYY') }}</small>-->
              <!--                    </h4>-->

              <!--                    <div-->
              <!--                      class="ml-4"-->
              <!--                      v-if="moment(dateItemIndex, 'DD-MM-YYYY').format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')">-->
              <!--                      <vs-chip color="success">-->
              <!--                        <vs-avatar icon="star" color="success"/>-->
              <!--                        DANES-->
              <!--                      </vs-chip>-->
              <!--                    </div>-->

              <!--                    <vs-chip color="primary" class="ml-5">-->
              <!--                      <vs-avatar :text="dateItem ? dateItem.length.toString() : '0'" color="primary"/>-->
              <!--                      št. prevozov-->
              <!--                    </vs-chip>-->

              <!--                  </div>-->
              <!--                </th>-->

              <!--              </tr>-->
              <tr>
                <th style="width: 30px">#</th>
                <th style="width: 120px">datum</th>
                <!--                <th style="width: 50px">ura</th>-->
                <th style="width: 250px">potnik</th>

                <th style="width: 300px">tip</th>
                <th style="width: 15%">JPP mesto odhoda</th>
                <th style="width: 15%">JPP mesto prihoda</th>
                <th style="width: auto">tip JPP</th>
                <th style="width: auto">povezana rezervacija</th>
                <th style="width: 250px">akcije</th>
              </tr>
              </thead>
              <tbody>
              <tr class="tr-values vs-table--tr tr-table-state-null selected"
                  v-for="(reservationItem, reservationItemIndex) in reservationsData"
                  @click.stop="onReservationRowClick(reservationItem)"
              >
                <td>{{ reservationItemIndex + 1 }}</td>
                <td>

                  {{ moment(reservationItem.departureTime).format('DD. MM. YYYY') }}

                </td>
                <!--                <td>-->
                <!--                  {{ moment(reservationItem.departureTime).format('HH:mm') }}-->
                <!--                </td>-->
                <td>
                  <div class="vs-col vs-xs- vs-sm- vs-lg-"
                       style="justify-content: flex-start; display: flex; align-items: center; margin-left: 0%; width: 100%;">
                    <vs-avatar size="60px" :src="reservationItem.customer.gender === 'male' ? sir : madam"
                               :color="reservationItem.customer.gender === 'male' ? '#dff8ff' : '#ffffdf'"/>
                    <div class="ml-2">{{
                        reservationItem.customer.firstName + ' ' + reservationItem.customer.lastName
                      }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="vs-col vs-xs- vs-sm- vs-lg-" v-if="reservationItem.type === 'FROM_HOME_TO_DESTINATION'"
                       style="justify-content: flex-start; display: flex; align-items: center; margin-left: 0%; width: 100%;">
                    <vs-avatar size="60px" icon="arrow_circle_up"/>
                    <div class="ml-2">od DOMA do DESTINACIJE</div>
                  </div>
                  <div class="vs-col vs-xs- vs-sm- vs-lg-" v-if="reservationItem.type === 'FROM_DESTINATION_TO_HOME'"
                       style="justify-content: flex-start; display: flex; align-items: center; margin-left: 0%; width: 100%;">
                    <vs-avatar size="60px" icon="arrow_circle_up"/>
                    <div class="ml-2">od DESTINACIJE do DOMA</div>
                  </div>
                </td>
                <td>
                  <span
                    v-if="reservationItem && reservationItem.departureStop && reservationItem.departureStop.addressName">{{ reservationItem.departureStop.addressName }}</span>
                  <span
                    v-if="reservationItem.instructions && JSON.parse(reservationItem.instructions)[0] && JSON.parse(reservationItem.instructions)[0].transit_details">{{ JSON.parse(reservationItem.instructions)[0].transit_details.departure_stop.name }}</span>
                </td>
                <td>
                  <span
                    v-if="reservationItem && reservationItem.arrivalStop && reservationItem.arrivalStop.addressName">{{ reservationItem.arrivalStop.addressName }}</span>
                  <span
                    v-if="reservationItem.instructions && JSON.parse(reservationItem.instructions)[0] && JSON.parse(reservationItem.instructions)[0].transit_details">{{ JSON.parse(reservationItem.instructions)[0].transit_details.arrival_stop.name }}</span>
                </td>
                <td>
                  <div class="vs-col vs-xs- vs-sm- vs-lg-"
                       style="justify-content: flex-start; display: flex; align-items: center; margin-left: 0%; width: 100%;"
                       v-if="reservationItem.instructions && JSON.parse(reservationItem.instructions)[0] && JSON.parse(reservationItem.instructions)[0].transit_details">

                    <vs-avatar
                      v-if="JSON.parse(reservationItem.instructions)[0].transit_details.line.vehicle.name === 'Vlak'"
                      size="60px" icon="train"/>
                    <vs-avatar
                      v-if="JSON.parse(reservationItem.instructions)[0].transit_details.line.vehicle.name === 'Avtobus'"
                      size="60px" icon="directions_bus"/>

                    <div class="ml-2">
                      {{ JSON.parse(reservationItem.instructions)[0].transit_details.line.vehicle.name }}
                    </div>
                  </div>
                </td>
                <td>
                  <span v-if="reservationItem.reservation">
                    <vs-button type="filled" color="warning" icon="search" size="small" class="btnx"
                               @click.stop="() => { selectedReservationId = reservationItem.reservation.id; sidebarActive = true; }">Poglej
                      </vs-button>
                  </span>
                  <span v-if="reservationItem.reservation === null">
                      <vs-button type="filled" color="success" icon="add" size="small" class="btnx"
                                 @click.stop="addSopotnikiReservation(reservationItem)">Dodaj
                      </vs-button>
                  </span>
                </td>
                <td>
                  <div class="vs-col vs-xs- vs-sm- vs-lg-"
                       style="justify-content: flex-start; display: flex; align-items: center; margin-left: 0%; width: 100%;">


                    <div class="split-btn vs-lg-12 ml-5">
                      <vs-button type="filled" color="primary" icon="edit" size="small" class="btnx"
                                 @click.stop="editReservation(reservationItem)">Uredi
                      </vs-button>
                      <vs-dropdown>
                        <vs-button class="btn-drop" type="filled" icon="expand_more" size="small"></vs-button>
                        <vs-dropdown-menu>
                          <vs-dropdown-item @click="() => { addSopotnikiReservation(reservationItem);}"
                                            v-if="reservationItem.reservation === null">
                            Dodaj povezano rezervacijo
                          </vs-dropdown-item>
                          <vs-dropdown-item divider
                                            @click="() => { addConnectedIntermodalReservation(reservationItem);}"
                                            v-if="reservationItem.connectedIntermodalReservation === null">
                            Dodaj povezan intermodalni prevoz
                          </vs-dropdown-item>
                          <vs-dropdown-item v-if="reservationItem.reservation"
                                            @click="() => { printTravelPlan(reservationItem);}">
                            Natisni potovalni načrt
                          </vs-dropdown-item>
                        </vs-dropdown-menu>
                      </vs-dropdown>
                    </div>

                    <!--                    <vs-button type="filled" color="primary" icon="edit" size="small" class="vs-lg-12 ml-5"-->
                    <!--                               @click.stop="editReservation(reservationItem)">Uredi-->
                    <!--                    </vs-button>-->

                    <vs-button type="filled" color="danger" icon="delete" size="small" class="vs-lg-12 ml-5"
                               @click.stop="beforeDeleteReservation(reservationItem)">Izbriši
                    </vs-button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>

    <div id="parentx">
      <vs-sidebar position-right parent="body" default-index="1" color="primary" class="unitSidebar" spacer
                  v-model="sidebarActive">

        <Reservation v-if="selectedReservationId" :reservationId="selectedReservationId" @closeSideBar="closeSideBar"/>

      </vs-sidebar>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import Datepicker from 'vuejs-datepicker';
import {slSI} from 'vuejs-datepicker/dist/locale';
import sir from "@/assets/images/portrait/sir.svg";
import madam from "@/assets/images/portrait/madam.svg";
import Reservation from '../../components/Sopotniki/reservations/Reservation';

export default {
  name: 'IntermodalReservations',

  components: {
    Datepicker,
    Reservation
  },

  data() {
    return {
      slSI: slSI,
      sir: sir,
      madam: madam,
      reservationsData: null,
      selectedReservationId: null,
      sidebarActive: false,
      selectedDate: new Date(),
      mode: null,
    }
  },

  computed: {},

  async mounted() {
    const _this = this;
    this.selectedDate = await this.$store.getters.getSelectedDateForIntermodalReservations;
    _this.getReservations();
  },

  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    const _this = this;
    _this.selectedReservationId = null;
    _this.sidebarActive = false;
    setTimeout(() => {
      next()
    }, 100);
  },

  methods: {
    getReservations() {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'intermodal-reservations', {
        params: {
          unit: _this.$globalFunctions.getCurrentUnit().id,
          startDate: _this.moment(_this.selectedDate).startOf('month').valueOf(),
          endDate: _this.moment(_this.selectedDate).endOf('month').valueOf()
        }
      })
        .then((res) => {
          _this.reservationsData = res.data.data;
          console.log("_this.reservationsData", _this.reservationsData);
          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    onDateChange() {
      this.$store.dispatch('setSelectedDateForIntermodalReservations', this.selectedDate);
      this.getReservations();
    },

    getCarsColor(carData) {
      const _this = this;

      if (carData && _this.$globalFunctions.getCarData(carData.id)) {
        return _this.$globalFunctions.getCarData(carData.id).color
      } else {
        return 'grey'
      }
    },

    getCarsName(carData) {
      const _this = this;

      if (carData && _this.$globalFunctions.getCarData(carData.id)) {
        return _this.$globalFunctions.getCarData(carData.id).name
      } else {
        return 'ni določeno'
      }
    },

    customDateFormatter(date) {
      return this.moment(date).format('MMMM, YYYY');
    },

    onReservationRowClick(reservation) {
      const _this = this;

      _this.selectedReservationId = parseInt(reservation.id);
      _this.sidebarActive = true;
    },

    closeSideBar(reloadReservations) {
      const _this = this;

      _this.selectedReservationId = null;
      _this.sidebarActive = false;

      if (reloadReservations) {
        _this.getReservations();
      }
    },

    duplicateReservation(reservationItem) {
      const _this = this;

      _this.$vs.loading();
      let reservation = reservationItem;

      reservation.id = null;
      reservation.created_at = new Date().toISOString();
      reservation.updated_at = new Date().toISOString();
      reservation.departure_time = new Date().toISOString();
      reservation.arrival_time = new Date().toISOString();
      reservation.volunteer_id = null;
      reservation.departure_address = {
        id: reservation.departure.id, //Update
        quick_search: (typeof reservation.departure.quick_search === "undefined" ? null : reservation.departure.quick_search.formatted_address) || reservation.departure.quick_search, //reservation.departure.quick_search.formatted_address || reservation.departure.quick_search,
        address_name: reservation.departure.address_name,
        route: reservation.departure.route,
        street_number: reservation.departure.street_number,
        postal_code: reservation.departure.postal_code,
        postal_town: reservation.departure.postal_town,
        municipality: reservation.departure.municipality,
        country: reservation.departure.country
      };
      reservation.arrival_address = {
        id: reservation.arrival.id, //Update
        quick_search: (typeof reservation.arrival.quick_search === "undefined" ? null : reservation.arrival.quick_search.formatted_address) || reservation.arrival.quick_search, //reservation.arrival.quick_search.formatted_address || reservation.arrival.quick_search,
        address_name: reservation.arrival.address_name,
        route: reservation.arrival.route,
        street_number: reservation.arrival.street_number,
        postal_code: reservation.arrival.postal_code,
        postal_town: reservation.arrival.postal_town,
        municipality: reservation.arrival.municipality,
        country: reservation.arrival.country
      };

      console.log("reservation after: ", reservation);


      Vue.prototype.$http.post(Vue.prototype.$config.api.productionOld + 'reservations/', reservation)
        .then((res) => {

          console.log("res", res.data);
          _this.$vs.loading.close();
          _this.$router.push({
            name: 'Rezervacija',
            params: {reservationId: res.data.data.id, createdViaDuplicateMethod: true}
          });

        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    beforeDeleteReservation(reservationItem) {
      const _this = this;

      Vue.$confirm({
        title: 'Ste prepričani, da želite izbrisati intermodalno rezervacijo uporabnika ' + reservationItem.customer.firstName + ' ' + reservationItem.customer.lastName + ' ?',
        message: 'Po potrditvi bo intermodalna rezervacija trajno izbrisana.',
        button: {
          yes: 'Izbriši',
          no: 'Prekliči'
        },
        callback: confirm => {
          console.log("confirm", confirm);
          if (confirm) {
            _this.deleteReservation(reservationItem);
          }
        }
      });
    },

    deleteReservation(reservationItem) {
      const _this = this;

      _this.$vs.loading();

      Vue.prototype.$http.delete(Vue.prototype.$config.api.sopotnikiVPS + 'intermodal-reservations/' + reservationItem.id)
        .then((res) => {

          _this.$vs.loading.close();
          _this.getReservations();

        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    editReservation(reservationItem) {
      const _this = this;

      _this.$router.push({name: 'IntermodalReservationEdit', params: {reservationId: reservationItem.id}});
    },

    addIntermodalReservation() {
      const _this = this;

      _this.$router.push({name: 'IntermodalReservationEdit'})
    },

    markReservationAsCanceled(reservationItem) {
      const _this = this;
      _this.$router.push({name: 'CanceledReservationEdit', params: {reservationItem: reservationItem}});
    },

    markReservationAsDeclined(reservationItem) {
      const _this = this;
      _this.$router.push({name: 'DeclinedReservationEdit', params: {reservationItem: reservationItem}});
    },

    addSopotnikiReservation(intermodalReservationData) {
      const _this = this;
      _this.$router.push({name: 'Rezervacija', params: {intermodalReservationData: intermodalReservationData}});
    },

    addConnectedIntermodalReservation(intermodalReservationData) {
      const _this = this;
      _this.$router.push({
        name: 'IntermodalReservationEdit',
        params: {intermodalReservationData: intermodalReservationData}
      });
    },

    printTravelPlan(intermodalReservationData) {
      const _this = this;
      //_this.$router.push({name: 'PdfTravelPlan', params: {intermodalReservationId: intermodalReservationData.id}});

      console.log("intermodalReservationData.id", intermodalReservationData.id);

      const type = intermodalReservationData.type === 'FROM_HOME_TO_DESTINATION' ? 'od_doma_do_destinacije' : 'od_destinacije_do_doma';

      _this.$vs.loading();

      Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent('https://admin-2-0.sopotniki.org/intermodal-transportation/potovalni-nacrt/' + intermodalReservationData.id + '/' + _this.$globalFunctions.getCurrentUnit().id + '?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token),
        {
          headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
        }
      )
        .then((res) => {
          const file = new Blob(
            [res.data],
            {type: 'application/pdf'});

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(file)
          link.download = `potovalni_nacrt_${type}.pdf`
          link.click();
          _this.$vs.loading.close();
        });


    }

  }
}
</script>

